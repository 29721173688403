export const ROUTE_PATH = {
    components: 'components1',
    newComponents: 'components',
    specifications: 'specifications',
    templates: 'templates',
    projects: 'projects',
    dictionary: 'dictionary',
    products: 'products',
    users: 'users',
    userGroups: 'groups',
    configurations: 'configurations',
    settings: 'settings',
    login: 'login',
    loginError: '/sso/error',
    nodeTypes: 'nodes',
    admin: 'admin',
    adminNodes: 'admin/nodes',
    adminNodeTraits: 'admin/traits',
};
