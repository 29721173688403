import {SpecItem} from "./spec.interface";

export interface ApiProjectListResult{
    total: number;
    result: ApiProject[];
}

export interface ApiProjectModifyRequest{
    projectId: number;
    specIds: number[]
}


export interface ApiProject{
    id: number;
    name: string;
    specsCount: number;
}

export interface ApiProjectDetails extends ApiProject{
    specs: SpecItem[];
    ownerId: number;
}

export enum ProjectAction {
    delete = 'Удалить',
    edit = 'Перейти',
    rename = 'Переименовать',
    addSpec = 'Добавить спецификации',
    deleteSpec = 'Удалить спецификации',
    permissions = 'Управлять доступом'
}
