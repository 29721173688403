import {AppPromptData, SpecItem} from "../../../interface";
import {ApiProjectDetails, ProjectAction} from "../../../interface/api-project.interface";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../hook/store";
import {DropDownButtonItemClickEvent} from "@progress/kendo-react-buttons/dist/npm/ListButton/models/events";
import {ROUTE_PATH} from "../../../constants/routes";
import React, {PropsWithChildren, useState} from "react";
import {getProject, removeProjectSpec, selectProject} from "../../../store/slice/project-slice";
import {AppPrompt} from "../../../components/app-prompt/app-prompt";
import {DropDownButton} from "@progress/kendo-react-buttons";

interface ProjectItemActionsProps extends PropsWithChildren{
    specification: SpecItem;
    project: ApiProjectDetails;
}

const ProjectItemActions = ({specification, project, children}: ProjectItemActionsProps) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const actionData: AppPromptData = {
        title: 'Подтвердите действие',
        message: [`Подтвердите удаление спецификации "${specification.name}" из проекта "${project.name}"`],
        confirmButton: 'Удалить',
        buttonError: true
    };


    const handleAction = (e: DropDownButtonItemClickEvent) => {
        switch (e.item.text) {
            case ProjectAction.edit:
                goToSpecification(e);
                break;
            case ProjectAction.delete:
                setShowConfirm(true);
                break;
        }
    };

    const goToSpecification = (e: DropDownButtonItemClickEvent) => {
        if (e.nativeEvent?.ctrlKey || e.nativeEvent?.metaKey) {
            window.open(`/${ROUTE_PATH.specifications}/${specification.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.specifications}/${specification.id}`);
        }
        if (e.nativeEvent) {
            e.nativeEvent.stopPropagation();
        }
    };

    const itemRender = (itemData: { item: any; itemIndex: number }) => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };


    const onDeleteSpec = () => {
        setShowConfirm(false);
        if (specification.id) {
            const specId = +specification.id.replace(/\.\s*(\w+)/g, '');
            dispatch(removeProjectSpec({
                projectId: project.id,
                specIds: [specId]
            })).unwrap()
                .finally(() => {
                    dispatch(getProject(project.id)).unwrap().then(
                        (data) => dispatch(selectProject(data))
                    );
                });
        }
    };


    return (
        <>
            <DropDownButton
                size={'small'}
                themeColor={'primary'}
                fillMode={'solid'}
                rounded={'small'}
                icon={'more-vertical'}
                itemRender={itemRender}
                onItemClick={handleAction}
            >
                {children}
            </DropDownButton>
            {showConfirm && (
                <AppPrompt data={actionData} onClose={() => setShowConfirm(false)} onConfirm={onDeleteSpec}/>
            )}
        </>
    );
};

export {ProjectItemActions};
