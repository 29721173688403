import {ColumnInterface} from "../../components";

export const projectColumns: ColumnInterface[] = [
    {
        title: 'Название',
        field: 'name',
        type: 'name',
        searchField: 'name',
        sortable: true,
        show: true,
        width: '300px',
    },
    {
        title: 'Кол-во спецификаций',
        field: 'specsCount',
        type: 'integer',
        show: true,
        sortable: false,
        width: '150px',
    }
];
