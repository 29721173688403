import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../hook/store";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {AppSuspense} from "../app-suspense/app-suspense";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {GridCellProps} from "@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps";
import {GridNoRecords as NoRecords} from "@progress/kendo-react-grid/dist/npm/GridNoRecords";
import {GridRowClickEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import styled from "@emotion/styled";
import {DeleteOutline, EditOutlined} from "@mui/icons-material";
import {ApiPermissionItem, ApiPermissions, ApiPermissionsItemAddMode} from "../../interface/api-permission.interface";
import {AppPrompt} from "../app-prompt/app-prompt";
import {AppPromptData} from "../../interface";
import {LoadingButton} from "@mui/lab";
import {AddItem} from "./components/add-item";
import {OptionListItem} from "../../interface/common.interface";

interface AppPermissionsProps {
    data: () => Promise<any>;
    onSave: (data: ApiPermissionItem[]) => Promise<any>;
    handleClose: () => void
}



const AppPermissions = ({data, onSave, handleClose}: AppPermissionsProps) => {

    const [permissions, setPermissions] = useState<ApiPermissionItem[]>();
    const [currentItem, setCurrentItem] = useState<ApiPermissionItem>();
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [addMode, setAddMode] = useState<ApiPermissionsItemAddMode>("user");
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const [actionData, setActionData] = useState<AppPromptData>({
        title: 'Подтвердите действие',
        message: [],
        confirmButton: 'Удалить',
        buttonError: true
    });

    useEffect(() => {
        if (typeof data === "function") {
            data().then(result => {
                setPermissions(sortPermissions(result.items));
            });
        }
    }, [data]);


    const sortPermissions = (data: ApiPermissionItem[]) => {
        if (data && Array.isArray(data)) {
            return data.sort((a, b) => {
                if (a.type !== b.type) {
                    return a.type.localeCompare(b.type);
                }
                return a.name.localeCompare(b.name);
            });
        }
    };

    const titleCell = (props: GridCellProps) => {
        const item: ApiPermissionItem = props.dataItem
        return <td>
            <FlexBox>
                <span>{item.type === "user" ? <i className="pi pi-user"></i> : (<i className="pi pi-users"></i>)}</span>
                <span> {item.name} {item.owner && "(владелец)"}</span>
            </FlexBox>
        </td>;
    };

    const actionCell = (props: GridCellProps) => {
        const item: ApiPermissionItem = props.dataItem;
        return <td>
            <IconButton color="secondary"
                        aria-label="delete"
                        disabled={item.owner}
                        onClick={() => handleItemDelete(props.dataItem)}
            >
                <DeleteOutline/>
            </IconButton>

        </td>;
    };

    const handleItemDelete = (item: ApiPermissionItem) => {
        setCurrentItem(item);
        const unit = item.type === "userGroup" ? "группы" : "пользователя";
        setActionData(prev => ({
            ...prev,
            message: [`Подтвердите удаление ${unit} "${item.name}"`]
        }));
        setDeleteConfirm(true);
    };

    const onDeleteItem = () => {
        setDeleteConfirm(false);
        if (currentItem) {
            setPermissions(prev => (prev?.filter((i) => !(i.id === currentItem.id && i.type === currentItem.type))));
        }
    };

    const handleRowClick = (e: GridRowClickEvent): void => {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        e.syntheticEvent.preventDefault();
        e.syntheticEvent.stopPropagation();
    };


    const handleSave = () => {
        if (permissions) {
            onSave(permissions).finally(() => {
                setInProgress(false)
                handleClose();
            });
            setInProgress(true);
        }
    };

    const handleAddItem = (mode: ApiPermissionsItemAddMode) => {
        setAddMode(mode);
        setAddDialog(true);
    };

    const updateItems = (items: OptionListItem[]) => {
        setAddDialog(false);
        const newItems: ApiPermissionItem[] = items.map((i) => {
            return {
                id: Number(i.value),
                name: i.title,
                owner: false,
                type: addMode
            }
        });

        setPermissions(sortPermissions([...permissions!, ...newItems ]));
    };


    return (
        <>
            <Dialog
                className="new-item-dialog"
                open={true}
                maxWidth={"sm"}
                fullWidth={true}
                onClose={handleClose}
            >
                <DialogTitle className="dialog-title">Управление доступом</DialogTitle>
                <DialogContent>
                    <AppSuspense condition={!!permissions}>
                        <FlexBox>
                            <Button
                                type={'button'}
                                variant={'outlined'}
                                onClick={() => handleAddItem("user")}
                            >Добавить пользователя</Button>
                            <Button
                                type={'button'}
                                variant={'outlined'}
                                onClick={() => handleAddItem("userGroup")}
                            >Добавить группу</Button>
                        </FlexBox>

                        <Grid
                            data={permissions}
                            style={{height: '100%', width: '100%'}}
                            scrollable={'none'}
                            rowHeight={40}
                            onRowClick={handleRowClick}
                        >
                            <GridColumn field="name" title=" " width={400} cell={titleCell}/>
                            <GridColumn field="" title=" " cell={actionCell}/>
                            <NoRecords>
                                {!permissions?.length && ('Список пуст.')}
                            </NoRecords>
                        </Grid>
                    </AppSuspense>
                </DialogContent>
                <DialogActions className="form-buttons">
                    <Button
                        disabled={inProgress}
                        variant={'outlined'}
                        onClick={handleClose}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={inProgress}
                        type={'button'}
                        variant={'contained'}
                        disabled={inProgress}
                        onClick={handleSave}
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {deleteConfirm && (
                <AppPrompt data={actionData} onClose={() => setDeleteConfirm(false)} onConfirm={onDeleteItem}/>
            )}
            {addDialog && (
                <AddItem mode={addMode} items={permissions} handleClose={()=>setAddDialog(false)} handleSave={updateItems}/>
            )}
        </>
    );
};

export {AppPermissions};

const FlexBox = styled.div(
    {
        display: "flex",
        gap: "8px",
        alignItems: "center",
    }
);

