import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";
import {createUserGroup} from "../../../store/slice/user-group-slice";
import {ROUTE_PATH} from "../../../constants/routes";
import {useState} from "react";
import {useAppDispatch} from "../../../hook/store";
import {useNavigate} from "react-router-dom";
import {addProject} from "../../../store/slice/project-slice";

interface NewProjectDialog {
    handleClose: () => void;
}

const NewProjectDialog = ({handleClose}: NewProjectDialog) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState<boolean>(false);

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'all'
    });


    const onSubmit = async (data: any) => {
        if (!data.projectName) {
            return;
        }

        setInProgress(true);
        await dispatch(addProject(data.projectName)).unwrap()
            .then((res) => {
                if (!!res) {
                    navigate(`/${ROUTE_PATH.projects}/${res.id}`);
                }
            }).finally(() => {
                setInProgress(false);
            });
    };


    return (
        <>
            <Dialog
                className="new-item-dialog"
                open={true}
                maxWidth={'lg'}
                onClose={handleClose}
            >
                <DialogTitle className="dialog-title">Новый проект</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <FormGroup className={errors?.projectName ? 'form-field form-field-error' : 'form-field'}>
                            <FormControl>
                                <TextField {...register('projectName', {
                                    required: 'Обязательное поле',
                                })}
                                           label="Название Проекта"
                                           variant="outlined"
                                           size={'small'}
                                           error={!!errors?.projectName}
                                           helperText={errors?.projectName ? errors?.projectName.message as string : ''}
                                />
                            </FormControl>
                        </FormGroup>

                    </DialogContent>
                    <DialogActions className="form-buttons">
                        <Button
                            disabled={inProgress}
                            variant={'outlined'}
                            onClick={handleClose}
                        >
                            Отмена
                        </Button>
                        <LoadingButton
                            loading={inProgress}
                            type={'submit'}
                            variant={'contained'}
                            disabled={!isValid || inProgress}
                        >
                            Добавить
                        </LoadingButton>
                    </DialogActions>
                </form>

            </Dialog>
        </>
    );
};

export {NewProjectDialog};
