import {useAppDispatch} from "../../../hook/store";
import { useSearchParams} from "react-router-dom";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {showError, showSuccess} from "../../../store/slice/toast-slice";
import {ApiProject} from "../../../interface/api-project.interface";
import {getProjectList, renameProject} from "../../../store/slice/project-slice";

interface RenameProjectDialogProps {
    project: ApiProject;
    handleClose: () => void;
}


const RenameProjectDialog = ({project, handleClose}: RenameProjectDialogProps) => {
    const dispatch = useAppDispatch();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [params, setParams] = useSearchParams();


    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'all'
    });

    const onSubmit = async (data: any) => {
        if (!data.projectName) {
            return;
        }

        setInProgress(true);
        await dispatch(renameProject({
            id: project.id,
            name: data.projectName
        })).unwrap()
            .then((res) => {
                dispatch(showSuccess("Проект успешно переименован"))
                dispatch(getProjectList(Object.fromEntries(params.entries())));
            }).catch(() => {
                dispatch(showError("Не удалось переименовать проект."));
            }).finally(() => {
                setInProgress(false);
                handleClose();
            });
    };


    return (
        <>
            <Dialog
                className="new-item-dialog"
                open={true}
                maxWidth={'lg'}
                onClose={handleClose}
            >
                <DialogTitle className="dialog-title">Переименовать проект</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <FormGroup className={errors?.projectName ? 'form-field form-field-error' : 'form-field'}>
                            <FormControl>
                                <TextField {...register('projectName', {
                                    required: 'Обязательное поле',
                                })}
                                           label="Название Проекта"
                                           variant="outlined"
                                           size={'small'}
                                           error={!!errors?.projectName}
                                           defaultValue={project.name}
                                           helperText={errors?.projectName ? errors?.projectName.message as string : ''}
                                />
                            </FormControl>
                        </FormGroup>

                    </DialogContent>
                    <DialogActions className="form-buttons">
                        <Button
                            disabled={inProgress}
                            variant={'outlined'}
                            onClick={handleClose}
                        >
                            Отмена
                        </Button>
                        <LoadingButton
                            loading={inProgress}
                            type={'submit'}
                            variant={'contained'}
                            disabled={!isValid || inProgress}
                        >
                            Переименовать
                        </LoadingButton>
                    </DialogActions>
                </form>

            </Dialog>
        </>
    );
};

export {RenameProjectDialog};
