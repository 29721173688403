import {ApiPermissionItem, ApiPermissionsItemAddMode} from "../../../interface/api-permission.interface";
import {Controller, useForm} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormHelperText,
} from "@mui/material";
import {AppMultiselect} from "../../../pages/admin/components/multiselect/app-multiselect";
import {OptionListItem} from "../../../interface/common.interface";
import axios from "axios";
import {ApiUser, ApiUserGroup, SpecItem} from "../../../interface";

interface AddItemProps {
    mode: ApiPermissionsItemAddMode;
    items?: ApiPermissionItem[];
    handleClose: () => void;
    handleSave: (items: OptionListItem[]) => void;
}

const AddItem = ({mode, items, handleClose, handleSave}: AddItemProps) => {
    const [options, setOptions] = useState<OptionListItem[] | undefined>();

    useEffect(() => {
        if (mode === "user") {
            axios.get('/api/user').then((res) => {
                setUsers(res.data.result);
            });
        }
        if (mode === "userGroup") {
            axios.get('/api/userGroup').then((res) => {
                setGroups(res.data.result);
            });
        }
    }, [mode])


    const title = useMemo(() => {
        return mode === 'user' ? 'Добавить пользователя' : 'Добавить группу';
    }, [mode]);

    const {
        handleSubmit,
        control,
        setError,
        formState: {errors, isValid},
    } = useForm<any>({mode: 'all'});

    const onSubmit = async (data: { items: OptionListItem[] }) => {
        if (!data.items.length) {
            setError("items", {type: "custom", message: `${mode === 'user' ? "Пользователи" : "Группы"} не выбраны`});
            return;
        }
        handleSave(data.items);
    };

    const setUsers = (users: ApiUser[]) => {
        if (items) {
            const filter = new Set(Array.from(items.filter((u) => u.type === "user").map((u) => u.id)));
            const allUsers = users.filter((u) => !filter.has(u.id));
            setOptions(allUsers.map((u: ApiUser) => {
                return {
                    title: u.fullName,
                    value: `${u.id}`
                };
            }));
        }
    };

    const setGroups = (groups: ApiUserGroup[]) => {
        if (items) {
            const filter = new Set(Array.from(items.filter((u) => u.type === "userGroup").map((u) => u.id)));
            const allGroups = groups.filter((g) => !filter.has(g.id));
            setOptions(allGroups.map((g: ApiUserGroup) => {
                return {
                    title: g.name,
                    value: `${g.id}`
                };
            }));
        }
    };

    return (
        <>
            <Dialog
                className="new-item-dialog"
                open={true}
                maxWidth={'lg'}
                onClose={() => handleClose()}
            >
                <DialogTitle className="dialog-title">{title}</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>

                        <FormGroup className={errors?.users ? 'form-field form-field-error' : 'form-field'}>
                            <>
                                <FormControl>

                                    <Controller
                                        control={control}
                                        name="items"
                                        defaultValue={[]}
                                        render={({field: {onChange, onBlur, value, ref}}) => (
                                            <AppMultiselect
                                                options={options}
                                                selected={[]}
                                                placeholder={`Выберите ${mode === "user" ? "пользователей" : "группы"} `}
                                                onChange={(e, v) => onChange(v)}
                                                limitTags={3}
                                            />
                                        )}/>
                                </FormControl>
                                <FormHelperText style={{
                                    color: '#d32f2f',
                                    fontSize: '0.75rem'
                                }}>{errors?.items ? errors.items.message as string : ''}</FormHelperText>
                            </>
                        </FormGroup>


                    </DialogContent>
                    <DialogActions className="form-buttons">
                        <Button
                            variant={'outlined'}
                            onClick={() => handleClose()}
                        >
                            Отмена
                        </Button>
                        <Button
                            type={'submit'}
                            variant={'contained'}
                            disabled={!isValid}
                        >
                            Добавить
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
        </>
    );
};

export {AddItem};
