import {useAppDispatch} from "../../../hook/store";
import {useSearchParams} from "react-router-dom";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormHelperText,
    FormLabel
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {AppMultiselect} from "../../admin/components/multiselect/app-multiselect";
import {LoadingButton} from "@mui/lab";
import React, {useRef, useState} from "react";
import {OptionListItem} from "../../../interface/common.interface";
import {getSpecifications} from "../../../store/slice/spec-slice";
import {SpecItem} from "../../../interface";
import {addProjectSpec, getProjectList} from "../../../store/slice/project-slice";


interface ProjectAddSpecDialogProps {
    projectId: number,
    handleClose: (confirm?: boolean) => void;
}


const ProjectAddSpecDialog = ({projectId, handleClose}: ProjectAddSpecDialogProps) => {
    const dispatch = useAppDispatch();
    const [params, setParams] = useSearchParams();
    const [availableSpecs, setAvailableSpecs] = useState<OptionListItem[] | undefined>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const searchValue = useRef<string>();

    const {
        handleSubmit,
        control,
        setError,
        formState: {errors, isValid},
    } = useForm<any>({mode: 'all'});

    const onSubmit = async (data: any) => {
        if (!data.specifications.length) {
            setError("specifications", {type: "custom", message: "Спецификации не выбраны"});
        } else {
            setInProgress(true)
            const ids = data.specifications.map((s:OptionListItem) => +s.value.replace(/\.\s*(\w+)/g, ''))
            const specIds: number[] = Array.from(new Set(ids));
            dispatch(addProjectSpec({projectId: projectId, specIds})).unwrap().then(() => {
                dispatch(getProjectList(Object.fromEntries(params.entries())));
                handleClose(true);
            }).finally(() => setInProgress(false));
        }
    };


    const handleSearchChange = (value: string) => {
        const trimmedValue = value.trim();
        searchValue.current = trimmedValue
        if (!trimmedValue) {
            setAvailableSpecs([])
        } else if (trimmedValue.length > 1) {
            setAvailableSpecs(undefined)
            dispatch(getSpecifications({searchQuery: value}))
                .unwrap()
                .then( (data) => {
                   if(searchValue.current === value){
                       setAvailableSpecs(data.result.map((spec: SpecItem) => ({title: `${spec.id} - ${spec.name}`, value: spec.id})))
                   }
                });
        }
    };


    return (
        <>
            <Dialog
                className="new-item-dialog"
                open={true}
                maxWidth={'lg'}
                onClose={() => handleClose()}
            >
                <DialogTitle className="dialog-title">Добавление спецификаций в проект</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <FormGroup className={errors?.users ? 'form-field form-field-error' : 'form-field'}>
                            <FormControl>
                                <FormLabel>
                                    Спецификации
                                </FormLabel>
                                <Controller
                                    control={control}
                                    name="specifications"
                                    defaultValue={[]}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <AppMultiselect
                                            options={availableSpecs}
                                            selected={[]}
                                            onChange={(e, v) => onChange(v)}
                                            limitTags={10}
                                            placeholder={"Выберите Спецификации"}
                                            onSearchChange={handleSearchChange}
                                        />
                                    )}/>
                            </FormControl>
                            {errors?.specifications && (
                                <FormHelperText style={{
                                    color: '#d32f2f',
                                    fontSize: '0.75rem'
                                }}>{errors.specifications.message as string}</FormHelperText>
                            )}
                            {!errors?.specifications && (
                                <FormHelperText style={{
                                    fontSize: '0.75rem'
                                }}>Начните вводить название, тип или номер спецификации</FormHelperText>
                            )}
                        </FormGroup>


                    </DialogContent>
                    <DialogActions className="form-buttons">
                        <Button
                            disabled={inProgress}
                            variant={'outlined'}
                            onClick={() => handleClose()}
                        >
                            Отмена
                        </Button>
                        <LoadingButton
                            loading={inProgress}
                            type={'submit'}
                            variant={'contained'}
                            disabled={!isValid || inProgress}
                        >
                            Добавить
                        </LoadingButton>
                    </DialogActions>
                </form>

            </Dialog>
        </>
    );
};

export {ProjectAddSpecDialog};
