import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {SpecItem} from "../../../../interface";
import {useAppDispatch, useAppSelector} from "../../../../hook/store";
import {useEffect, useState} from "react";
import {addProjectSpec, getProjectList, ProjectState, removeProjectSpec} from "../../../../store/slice/project-slice";
import {ApiProject} from "../../../../interface/api-project.interface";

interface ChangeProjectProps {
    itemData: SpecItem;
    onClose: () => void;
    onConfirm: () => void;
}

const ChangeProject = ({onClose, itemData, onConfirm}: ChangeProjectProps) => {
    const dispatch = useAppDispatch();
    const {projectList} = useAppSelector<ProjectState>(state => state.project);
    const [currentProject, setCurrentProject] = useState<ApiProject | null>( null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if(!projectList){
            dispatch(getProjectList({}));
        }else if(Array.isArray(projectList)) {
            const project = projectList.find((p) => p.id === itemData.projectId);
            if (project) {
                setCurrentProject(project)
            }
        }
    }, [projectList]);

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: ApiProject) => option.name,
    });

    const handleConfirm = () => {

        if(!currentProject){
            if(!itemData.projectId){
                onClose()
            }else{
                setIsLoading(true);
                dispatch(removeProjectSpec({projectId: itemData.projectId, specIds:[Number(itemData.serial)]})).unwrap().
                finally(() => {
                    setIsLoading(false);
                    onConfirm();
                });
            }
        }else{
            if(currentProject.id === itemData.projectId ){
                onClose();
            }else{
                setIsLoading(true);
                if(itemData.projectId){
                    dispatch(removeProjectSpec({projectId: itemData.projectId, specIds:[Number(itemData.serial)]}))
                        .unwrap()
                        .then( () => {
                            dispatch(addProjectSpec({projectId: currentProject.id, specIds:[Number(itemData.serial)]}))
                                .unwrap()
                                .finally(() => {
                                    setIsLoading(false);
                                    onConfirm();
                                });
                        });
                }else{
                    dispatch(addProjectSpec({projectId: currentProject.id, specIds:[Number(itemData.serial)]}))
                        .unwrap()
                        .finally(() => {
                            setIsLoading(false);
                            onConfirm();
                        });
                }

            }
        }
    };

    return (
        <>
            <Dialog
                className="action-prompt-dialog"
                open={true}
                maxWidth={'sm'}
                onClose={onClose}
            >
                <DialogTitle className="dialog-title">
                    {!!itemData.projectId ? "Изменить проект" : "Назначить проект"}
                </DialogTitle>
                <DialogContent>
                    <div className={'k-py-4'}>Спецификация добавлена в проект:</div>
                    <div className={'k-py-2'}>
                        <Autocomplete
                            id="project"
                            options={projectList || []}
                            getOptionLabel={(res) => res.name}
                            filterOptions={filterOptions}
                            isOptionEqualToValue={(o, v) => o.id == v.id}
                            noOptionsText={'проекты не найдены'}
                            value={currentProject}
                            onChange={(event, newValue) => {
                                setCurrentProject(newValue);
                            }}
                            renderOption={(props, res) => (
                                <Box component={'li'} key={res.id} {...props}>
                                    {res.name}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label={'Проект'}/>}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'outlined'}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleConfirm}
                        loading={isLoading}
                        disabled={Number(currentProject?.id) === Number(itemData.id)}
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export {ChangeProject};
