import {SpecificationItemTabFormData} from './spec-item.interface';

export type SpecColumnFormat = 'number' | 'money' | null;

export interface SpecInterface {
    id: string;
    typeId: string;
    serial: number;
    version: number;
    status: SpecItemStatus;
    editable: boolean;
    name: string;
    created: string;
    published: string;
    lastModified: string;
    author: string;
}

export interface SpecResultInterface {
    result: SpecInterface[];
    total: number;
}

export interface SpecTemplateResultInterface {
    result: SpecTemplate[];
    total: number;
}

export interface SpecTemplateGroupResultInterface {
    result: string[];
    total: number;
}


export interface SpecCreateInterface {
    typeId: string;
    name: string;
    comment: string;
}


export interface SpecItem {
    id: string;
    typeId: string;
    typeName?: string;
    serial: number;
    version: number;
    meta: SpecItemMeta;
    status: SpecItemStatus;
    editable: boolean;
    name: string;
    comment: string;
    doc: SpecItemDoc;
    created: string;
    published?: boolean;
    lastModified: string;
    author: string;
    masterSpecificationId: string;
    stageNumber: string | null;
    stages: SpecItem[];
    expertMode: boolean;
    projectName: string;
    projectId: number;
    supportYears?: number;
    deliveryWeeks?: number;
    currencyRate?: number;
    price?: number;
    class?: string;
    networkClass?: string;
    generation?: number;
    groupName?: string;
}

export interface SpecItemMeta {
    formTabs: any[];
    forms: any;
}

export interface SpecItemDoc {
    forms: SpecItemDocForm[];
    invoice: SpecItemDocInvoice;
    model: SpecItemDocModel;
    properties: any;
    validation: SpecItemValidation
}

export interface SpecItemDocInvoice {
    columns: SpecItemDocInvoiceColumn[];
    lines: SpecItemDocInvoiceLine[];
    summary: SpecItemDocInvoiceSummary;
    footer: SpecItemDocInvoiceFooter;
    header: SpecItemDocInvoiceHeader;
    isEmpty: boolean;
}

export interface SpecItemDocInvoiceHeader {
    columns: SpecItemDocInvoiceColumn[];
    lines: SpecItemDocInvoiceLine[];
}

export interface SpecItemDocInvoiceFooter {
    columns: SpecItemDocInvoiceColumn[];
    lines: SpecItemDocInvoiceLine[];
}

export interface SpecItemDocInvoiceColumn {
    columnId: string;
    format: SpecColumnFormat;
    title: string;
}

export interface SpecItemDocInvoiceLine {
    class: string;
    data: SpecItemDocInvoiceLineData;
    lineId: string;
}

export interface SpecItemDocInvoiceLineData {
    [key: string]: any;
}

export interface SpecItemDocInvoiceSummary {
    primaryCurrencyCode: CurrencyCode;
    primaryCurrencyRate: number;
    total: number
    totalPrimaryCurrency: number;
}

export interface SpecItemDocForm {
    data: any;
    visible: boolean;
}

export interface SpecItemValidation {
    messages: SpecItemValidationItem[];
}

export interface SpecItemValidationItem {
    id: string;
    text: string;
    type: SpecItemValidationItemType;
}

export interface SpecItemDocModel {
    empty: boolean;
    calculated: SpecItemDocModelCalculated
    root: SpecItemDocModelItem;
    sections: { [key: string]: SpecItemDocModelSection }
    properties?: any;
}


export interface SpecItemDocModelCalculated {
    id: string
    name: string
    node: SpecItemDocModelCalculatedNode
    links: SpecItemDocModelCalculatedLinks
    typeId: string
    quantity: number
    sourceNodeId: number
}

export interface SpecItemDocModelCalculatedNode {
    [key: string]: any
}

export interface SpecItemDocModelCalculatedLinks {
    id: string
    name: string
    node: SpecItemDocModelCalculatedNode
    links: SpecItemDocModelCalculatedLinks
    typeId: string
    quantity: number
    sourceNodeId: number
}

export interface SpecItemDocModelItem {
    id: string;
    items: SpecItemDocModelItem[];
    modelData: any;
    typeData: SpecItemDocModelItemTypeData;
    typeId: string;
    nodeTypeId: string;
}

export interface SpecItemDocModelSection {
    name: string;
    type: string;
    isEmpty: boolean;
    lines: SpecItemDocModelSectionLine[];
    columns: SpecItemDocModelSectionColumn[];
    header: SpecItemDocModelHeader;
    footer: SpecItemDocModelFooter;
}

export interface SpecItemDocModelHeader {
    lines: SpecItemDocModelSectionLine[];
    columns: SpecItemDocModelSectionColumn[];
}

export interface SpecItemDocModelFooter {
    lines: SpecItemDocModelSectionLine[];
    columns: SpecItemDocModelSectionColumn[];
}

export interface SpecItemDocModelSectionColumn {
    title: string;
    columnId: string;
    format: SpecColumnFormat;
}

export interface SpecItemDocModelSectionLine {
    class: string;
    lineId: string;
    data: { [key: string]: string }
}

export interface SpecItemDocModelItemModelData {
    rackNo?: number;
}

export interface SpecItemDocModelItemTypeData {
    name: string;
    partNumber?: string;
    code?: string;
    logicalCapacity?: number;
}

export enum SpecItemValidationItemType {
    'information' = 'information',
    'warning' = 'warning',
    'error' = 'error'
}

export enum SpecItemStatus {
    draft = 'draft',
    published = 'published',
    archive = 'archive'
}

export enum StatusLabel {
    draft = 'Черновик',
    published = 'Опубликован',
    archive = 'Архивный'
}

export enum SpecStatusCommand {
    'publish' = 'publish',
    'archive' = 'archive',
    'unarchive' = 'unarchive'
}

export interface SpecCommentRequest {
    id: string
    data: {
        comment: string;
    }
}

export interface SpecTemplate {
    id: string;
    name: string;
    typeName: string;
    typeId: string;
    created: string;
    groupName: string;
}

export enum SpecTemplateAction {
    create = 'Создать',
    rename = 'Свойства шаблона',
    delete = 'Удалить',
}

export interface SpecCreateTemplateRequest {
    specId: string;
    templateName: string;
    groupName: string;
    forms: any;
}

export interface SpecRenameRequest {
    id: string
    data: {
        name: string;
    }
}

export interface SpecCloneRequest {
    id: string
    name: string;
}

export interface SpecChangeStatusRequest {
    id: string;
    command: string;
}

export interface SpecFormsRequest {
    id: string
    forms: SpecificationItemTabFormData
}

export interface ApiChangeCurrencyRateRequest {
    id: string;
    data: {
        exchangeRate: string;
    }
}

export enum CurrencyCode {
    USD = '$',
    EUR = '€',
    RUR = '₽'
}


export interface SpecMultipleDownload {
    sections: SpecMultipleDownloadSection[]
}

export interface SpecMultipleDownloadSection {
    section: string;
    sectionName: string;
    schema: boolean;
}
