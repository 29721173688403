import {ApiProjectDetails, ProjectAction} from "../../../interface/api-project.interface";
import {Card, CardBody} from "@progress/kendo-react-layout";
import {Grid, GridCellProps, GridColumn, GridSortChangeEvent} from "@progress/kendo-react-grid";
import {GridNoRecords as NoRecords} from "@progress/kendo-react-grid/dist/npm/GridNoRecords";
import {AppPagination} from "../../../components/app-pagination/app-pagination";
import {AppSuspense} from "../../../components/app-suspense/app-suspense";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {SearchParams, SpecItem} from "../../../interface";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {DropDownButtonItem} from "@progress/kendo-react-buttons";
import {ProjectItemActions} from "./project-item-actions";

interface ProjectItemGridProps {
    project: ApiProjectDetails | null;
    searchTerm: string;
}

const ProjectItemGrid = ({project, searchTerm}: ProjectItemGridProps) => {

    const [sort, onSort] = useState<Array<SortDescriptor>>([]);
    const [page, setPage] = useState<SearchParams>({offset: '0', limit: '10'});

    const handlePageChange = (params: SearchParams) => {
        setPage(params)
    };


    const filteredProjects = useMemo(() => {
        if (!!project?.specs) {
            const filtered =  project.specs.filter((spec) => {
                return spec.name.toLowerCase().includes(searchTerm.toLowerCase())
                    || spec.id.toLowerCase().includes(searchTerm.toLowerCase())
            });
            const ordered = orderBy(filtered, sort);
            return ordered.slice(Number(page.offset), Number(page.limit) + Number(page.offset))
        } else {
            return [];
        }
    }, [searchTerm, project, page, sort]);

    const gridActions = (props: GridCellProps): ReactElement => {
        const spec:SpecItem = props.dataItem;
        return <td style={{textAlign: 'right'}}>
            <ProjectItemActions project={project!} specification={spec}>
                <DropDownButtonItem text={ProjectAction.edit}/>
                <DropDownButtonItem text={ProjectAction.delete}/>
            </ProjectItemActions>
        </td>;
    };

    useEffect(() => {
        setPage({offset: "0", limit: Math.floor((window.innerHeight - 270) / 64).toString()});
    }, []);

    return (
        <>
            <AppSuspense condition={!!filteredProjects}>
                <Card className="h100">
                    <CardBody>
                        <Grid
                            style={{height: '100%', width: '100%'}}
                            sortable={true}
                            scrollable={'none'}
                            sort={sort}
                            data={filteredProjects || []}
                            onSortChange={(e: GridSortChangeEvent) => {
                                onSort(e.sort);
                            }}
                            rowHeight={60}
                        >
                            <GridColumn field="id" title="№" width={100}/>
                            <GridColumn field="typeName" title="Тип" width={200}/>
                            <GridColumn field="name" title="Название"/>
                            <GridColumn field="" title="" width={100} cell={gridActions}/>

                            <NoRecords>
                                {!!project?.specs && !project?.specs?.length && ('Список пуст.')}
                                {!project?.specs && ('Загрузка данных...')}
                            </NoRecords>
                        </Grid>
                        <AppPagination params={page} total={project?.specs?.length || 0} onPageChange={handlePageChange}/>
                    </CardBody>
                </Card>

            </AppSuspense>
        </>
    );
};

export {ProjectItemGrid};
