import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SearchParams} from "../../interface";
import baseRequest from "../../utils/base-request";
import {
    ApiProject,
    ApiProjectDetails,
    ApiProjectListResult,
    ApiProjectModifyRequest
} from "../../interface/api-project.interface";


export const getProjectList = createAsyncThunk(
    'project/getProjectList',
    async function (params: SearchParams, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'get',
                url: '/api/project',
                params
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);


export const addProject = createAsyncThunk(
    'project/addProject',
    async function (name: string, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'post',
                url: '/api/project',
                data: {
                    name
                }
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);

export const renameProject = createAsyncThunk(
    'project/renameProject',
    async function (projectData:{id: number; name: string }, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'put',
                url: `/api/project/${projectData.id}/rename`,
                data: {
                    name: projectData.name
                }
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);


export const getProject = createAsyncThunk(
    'project/getProject',
    async function (id: number, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'get',
                url: `/api/project/${id}`,
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);

export const deleteProject = createAsyncThunk(
    'project/deleteProject',
    async function (id: number, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'delete',
                url: `/api/project/${id}`,
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);

export const addProjectSpec = createAsyncThunk(
    'project/addProjectSpec',
    async function (data: ApiProjectModifyRequest, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'put',
                url: '/api/project/addSpecs',
                data
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);


export const removeProjectSpec = createAsyncThunk(
    'project/removeProjectSpec',
    async function (data: ApiProjectModifyRequest, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'put',
                url: '/api/project/removeSpecs',
                data
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);


export interface ProjectState {
    projectList: ApiProject[] | null;
    selectedProject: ApiProjectDetails | null;
    total: number;
}


const initialState: ProjectState = {
    projectList: null,
    selectedProject: null,
    total: 0
};


const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        selectProject(state: ProjectState, action: PayloadAction<ApiProjectDetails>) {
            state.selectedProject = action.payload;
        },
        clearSelectedProject(state: ProjectState) {
            state.selectedProject = null;
        },
        clearProjectList(state: ProjectState) {
            state.projectList = null;
        },
        resetProjectState() {
            return initialState;
        }
    },
    extraReducers: {
        [getProjectList.fulfilled as any]: (state: ProjectState, action: PayloadAction<ApiProjectListResult>) => {
            state.projectList = action.payload.result;
            state.total = action.payload.total;
        }
    }
});


export const {selectProject, clearSelectedProject, resetProjectState, clearProjectList} = projectSlice.actions;

export default projectSlice.reducer;
