import {AppBreadcrumbs} from '../../../components/app-breadcrumbs/app-breadcrumbs';
import {SectionHeader} from '../../../components/section-header/section-header';
import {SECTION_HEADER} from '../../../constants';
import {CommonGrid} from '../../../components/common-grid/common-grid';
import {NewTypeDialog} from '../components/new-type-dialog/new-type-dialog';
import React, {ReactElement, useState} from 'react';
import {useAppDispatch} from '../../../hook/store';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {adminTraitsBreadcrumbs, adminTraitSchema, traitsListColumns} from './node-traits.meta';
import axios from 'axios';
import {API_ADMIN_NODE_TRAITS} from '../admin.meta';
import {ROUTE_PATH} from '../../../constants/routes';
import {NODE_FILTER} from '../../component/node-item/node-item.interface';
import {AdminTrait} from '../../../interface/admin/admin-traits.interface';
import {SortDescriptor} from '@progress/kendo-data-query';
import {GridRowClickEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import ObjectHelper from '../../../helpers/object.helper';
import {GridCellProps} from '@progress/kendo-react-grid';
import {showSuccess} from '../../../store/slice/toast-slice';
import {TraitsItemActions} from './actions/traits-item-actions';
import {AppPrompt} from "../../../components/app-prompt/app-prompt";

const NodeTraitsList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<AdminTrait[] | null>(null);
    const [total, setTotal] = React.useState<number>(0);
    const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
    const [showExistingType, setShowExistingType] = useState<boolean>(false);
    const [typeToSave, setTypeToSave] = useState<{ name: string }>({name: ''});
    const [inProgress, setInProgress] = useState<boolean>(false);


    const getPageData = () => {
        axios.get(API_ADMIN_NODE_TRAITS, {params: searchParams})
            .then((response) => {
                setData(response?.data?.result || []);
                setTotal(response?.data?.total || 0);
            });

    };


    const handleSearch = (term: string) => {
        if (term.trim().length) {
            setSearchParams(
                {
                    ...Object.fromEntries(searchParams.entries()),
                    offset: '0',
                    searchQuery: term.trim()
                });
        } else {
            searchParams.delete('searchQuery');
            setSearchParams(searchParams);
        }
    };


    const handleCreate = (data: { name: string, version?: number }) => {
        const code = data.name.toUpperCase();
        axios.post(API_ADMIN_NODE_TRAITS, {
            code,
            data: adminTraitSchema.data
        }).then((res) => {
            navigate(`/${ROUTE_PATH.adminNodeTraits}/${res.data.id}`);
        }).finally(() => {
            setShowNewDialog(false);
        });
    };

    const handleRowClick = (data: GridRowClickEvent): void => {
        if (data?.nativeEvent?.ctrlKey || data?.nativeEvent?.metaKey) {
            window.open(`/${ROUTE_PATH.adminNodeTraits}/${data.dataItem.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.adminNodeTraits}/${data.dataItem.id}`);
        }
        data?.nativeEvent?.stopPropagation();
    };

    const handleSort = (data: SortDescriptor[]): void => {
        if (!!data[0] && !!data[0].dir) {
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0',
                sortBy: data[0].field,
                sortDirection: data[0].dir
            });
        } else {
            searchParams.delete('sortBy');
            searchParams.delete('sortDirection');
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0'
            });
        }
    };

    const handleUpdateFilter = (field: string, filter: string) => {
        if (!!filter) {
            if (field === NODE_FILTER.types) {
                const params = ObjectHelper.pickParams(Object.fromEntries(searchParams.entries()), ['limit']);
                setSearchParams(
                    {
                        ...Object.fromEntries(searchParams.entries()),
                        ...params,
                        offset: '0',
                        [field]: filter
                    });
            } else {
                setSearchParams(
                    {
                        ...Object.fromEntries(searchParams.entries()),
                        offset: '0',
                        [field]: filter
                    });
            }
        }
    };

    const handleReload = () => {
        getPageData();
        dispatch(showSuccess('Характеристика успешно переименована'));
    };

    const gridActions = (props: GridCellProps): ReactElement => {
        return <td className={props.className} style={{...props.style, textAlign: 'right'}}>
            <TraitsItemActions traitsItem={props.dataItem}
                             onReload={handleReload}/>
        </td>;
    };


    const checkExistingType = (data: { name: string }) => {
        setInProgress(true)
        const code = data.name.toUpperCase();
        const searchQuery = `${code}.`;
        axios.get(API_ADMIN_NODE_TRAITS, {
            params: {
                searchQuery
            }
        }).then((res) => {
            const items = res?.data?.result || []
            if (items.some((item: AdminTrait) => item.id.startsWith(searchQuery))) {
                setTypeToSave(data);
                setShowExistingType(true)
            } else {
                handleCreate(data);
            }
        });
    };

    const handleConfirmExistingType = () => {
        setShowExistingType(false);
        handleCreate(typeToSave);
    };

    const handleCloseExistingType = () => {
        setShowExistingType(false);
        setInProgress(false)
    };


    return (
        <div className={'node-type'}>
            <div className="node-type__breadcrumbs">
                <AppBreadcrumbs items={adminTraitsBreadcrumbs}/>
            </div>
            <div className="node-type__header">
                <SectionHeader onSearch={handleSearch}
                               onCreate={() => setShowNewDialog(true)}
                               type={SECTION_HEADER.nodeType}
                               style={'inline'}
                />
            </div>
            <div className="node-type__grid">
                <CommonGrid
                    columnsSet={traitsListColumns}
                    data={data}
                    total={total}
                    onRowClick={handleRowClick}
                    gridActions={gridActions}
                    requestData={getPageData}
                    exportLink={'/api/admin/nodeTraits/export/excel'}
                />
            </div>
            {showNewDialog && (
                <NewTypeDialog
                    onClose={() => setShowNewDialog(false)}
                    onSubmit={checkExistingType}
                    title={'Новая характеристика'}
                    namePlaceholder={'Название типа характеристики'}
                    inProgress={inProgress}
                />
            )}
            {showExistingType && (
                <AppPrompt data={{
                    title: 'Тип характеристики',
                    message: [`Тип характеристики '${typeToSave.name.toUpperCase()}' уже существует`, 'Сохранить как новую версию типа характеристики?'],
                    confirmButton: 'Сохранить',
                    buttonError: false
                }}
                           onClose={handleCloseExistingType}
                           onConfirm={handleConfirmExistingType}
                />
            )}
        </div>
    );
};

export {NodeTraitsList};
