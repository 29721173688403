import {ColumnInterface, TypeColumnMenu} from '../index';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridSortChangeEvent} from '@progress/kendo-react-grid';
import {GridNoRecords as NoRecords} from '@progress/kendo-react-grid/dist/npm/GridNoRecords';
import {AppPagination} from '../app-pagination/app-pagination';
import {AppSuspense} from '../app-suspense/app-suspense';
import React, {ReactElement, useEffect, useState} from 'react';
import {SortDescriptor} from '@progress/kendo-data-query';
import {useSearchParams} from 'react-router-dom';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {SearchParams} from '../../interface';
import {DEFAULT_PAGE_SIZE, PAGE_SIZE} from '../../constants';
import {TextFilter} from "../../pages/component/components/grid-header-filters/text-filter";
import {NODE_FILTER} from "../../pages/component/node-item/node-item.interface";
import {FilterHeader} from "../../pages/component/components/grid-header-filters/components/filter-header";

export interface CommonGrid {
    columnsSet: ColumnInterface[];
    data: any[] | null;
    total: number;
    onRowClick: (data: any) => void;
    gridActions: (props: GridCellProps) => ReactElement;
    exportLink?: string;
    requestData: () => void;
}


const CommonGrid = ({
                        columnsSet,
                        data,
                        total,
                        onRowClick,
                        gridActions,
                        exportLink,
                        requestData
                    }: CommonGrid) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [sort, setSort] = useState<SortDescriptor[]>([{field: '', dir: 'asc'}]);
    const [columns, setColumns] = useState<ColumnInterface[]>([]);

    useEffect(() => {
        checkSort();
        checkLimit();
    }, [searchParams]);


    useEffect(() => {
        const columnsParam = searchParams.get('columns');
        if (columns.length === 0) {
            setColumns(columnsSet);
        } else if (columnsParam && columns.length > 0) {
            const columnsParamsArr = columnsParam.split(';');
            const newColumns = sortColumns()
                .map(column => column.field && columnsParamsArr.includes(column.field) ? {
                    ...column,
                    headerCell: (props: GridHeaderCellProps) => getHeaderCell(props, column),
                    show: true,
                } : {
                    ...column,
                    headerCell: (props: GridHeaderCellProps) => getHeaderCell(props, column),
                    show: false,
                });
            setColumns(newColumns);
        }
    }, [searchParams, columns.length]);


    const sortColumns = (): ColumnInterface[] => {
        const columnSet = getColumnsSet();
        const columnsParam = searchParams.get('columns');
        let fullColumns = [...columnSet];
        if (columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            fullColumns = fullColumns.sort((a, b) => {
                const indexA = columnsParamsArr.indexOf(a.field!);
                const indexB = columnsParamsArr.indexOf(b.field!);
                return indexA - indexB;
            });
        }
        return fullColumns;
    };

    const getColumnsSet = (): ColumnInterface[] => {
        return !columns.length ? columnsSet : columnsSet.map((col) => (
            {
                ...col,
                show: !!columns.find((c) => c.title === col.title && c.type === col.type && c.show),
            }
        ));
    };

    const getHeaderCell = (props: GridHeaderCellProps, column: ColumnInterface) => {
        if (!column.searchField) {
            return <div title={props.title} onClick={props.onClick}>
                {props.title} {props.children}
            </div>;
        }
        return <>{textSearchCell(props)}</>;
    };


    const textSearchCell = (props: any): ReactElement<any> => {
        return <div>
            <TextFilter
                {...props}
                onFilter={(filter) => updateFilter(NODE_FILTER.searchQuery, filter)}
                selected={searchParams.get(NODE_FILTER.searchQuery)}
                onReset={() => resetFilter(NODE_FILTER.searchQuery)}
            />
        </div>;
    };


    const updateFilter = (field: string, filter: string) => {
        if (!!filter) {
            setSearchParams(
                {
                    ...Object.fromEntries(searchParams.entries()),
                    offset: '0',
                    [field]: filter
                });
        }
    };

    const resetFilter = (field: string) => {
        searchParams.delete(field);
        setSearchParams(searchParams);
    };


    const checkLimit = () => {
        const limitParam = searchParams.get('limit');
        const columnsParam = searchParams.get('columns');
        if (!limitParam || !columnsParam) {
            const selectedColumns = columns.filter(
                (column) => column.show
            ).map(column => column.field).join(';');

            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0',
                limit: searchParams.get('limit') || DEFAULT_PAGE_SIZE.toString(),
                columns: columnsParam || selectedColumns
            }, {replace: true});
        } else {
            requestData();
        }
    };


    const checkSort = () => {
        const sortBy = searchParams.get('sortBy');
        const sortDir = searchParams.get('sortDirection');
        if (!!sortBy && !!sortDir) {
            setSort([
                {
                    field: sortBy,
                    dir: sortDir as any
                }
            ]);
        } else {
            setSort([]);
        }
    };


    const handleReorderColumns = (event: GridColumnReorderEvent) => {
        const newOrder = [...event.columns];
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            'columns': newOrder.sort((a, b) => a.orderIndex! - b.orderIndex!).map(col => col.field).join(';'),
        }, {replace: true});
    };


    const handleSort = (data: SortDescriptor[]): void => {
        if (!!data[0] && !!data[0].dir) {
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0',
                sortBy: data[0].field,
                sortDirection: data[0].dir
            });
        } else {
            searchParams.delete('sortBy');
            searchParams.delete('sortDirection');
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0'
            });
        }
    };


    const onColumnsChange = (newColumns: ColumnInterface[]) => {
        const columnsParam = searchParams.get('columns');
        let sortedColumns: ColumnInterface[] = [];
        let defaultCols: ColumnInterface[] = [];
        if (columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            const idCol = newColumns.find(col => col.field === 'id');
            if (idCol && !columnsParamsArr.includes('id')) {
                sortedColumns[0] = idCol;
                defaultCols = newColumns.filter(col => col.field !== 'id' && columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            } else if (idCol) {
                defaultCols = newColumns.filter(col => columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            }
            const others: ColumnInterface[] = newColumns.filter(col => defaultCols.every(c => c.field !== col.field)) || [];

            sortedColumns = [...sortedColumns, ...defaultCols, ...others];
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                'columns': sortedColumns.filter(col => col?.show).map(col => col?.field).join(';'),
            }, {replace: true});
        }
    };

    const handlePageChange = (params: SearchParams): void => {
        setSearchParams(
            {
                ...Object.fromEntries(searchParams.entries()),
                offset: params.offset || '0',
                limit: params.limit || DEFAULT_PAGE_SIZE.toString(),
            });
    };

    const handleUpdateFilter = (field: string, filter: string) => {
        if (!!filter) {
            setSearchParams(
                {
                    ...Object.fromEntries(searchParams.entries()),
                    offset: '0',
                    [field]: filter
                });
        }
    };

    return (
        <AppSuspense condition={!!columns}>
            <Card>
                <CardBody>
                    <div className={'universal-grid'}>
                        <Grid
                            className={'node-grid'}
                            style={{height: 'calc(100vh - 274px)', width: '100%'}}
                            sortable={true}
                            onSortChange={(e: GridSortChangeEvent) => {
                                handleSort(e.sort);
                            }}
                            sort={sort}
                            data={data ?? []}
                            reorderable={true}
                            resizable={true}
                            onRowClick={onRowClick}
                            onColumnReorder={handleReorderColumns}
                        >
                            {columns.map((col, idx) => col.show && (
                                <GridColumn
                                    key={idx}
                                    orderIndex={idx}
                                    field={col.field}
                                    title={col.title?.toString()}
                                    sortable={col.sortable}
                                    width={col.width || 'auto'}
                                    headerCell={(props) => getHeaderCell(props, col)}
                                    minResizableWidth={100}
                                />
                            ))}
                            <GridColumn field="" title="" width={100}
                                        orderIndex={columns.length + 1}
                                        headerClassName={'actions-menu'}
                                        reorderable={false}
                                        resizable={false}
                                        locked={true}
                                        cell={gridActions}
                                        headerCell={() => (
                                            <TypeColumnMenu
                                                columns={columns}
                                                searchParams={searchParams}
                                                onFilter={(filter: string) => handleUpdateFilter('columns', filter)}
                                                onColumnsChange={onColumnsChange}
                                                exportLink={exportLink}

                                            />
                                        )}/>


                            <NoRecords>
                                {!!data && !data?.length && ('Список пуст.')}
                                {!data && ('Загрузка данных...')}
                            </NoRecords>

                        </Grid>
                    </div>
                    <div className="node-pager-wrapper">
                        <div className="page-info">

                        </div>
                        <div className="node-pager">
                            <AppPagination params={{
                                offset: searchParams.get('offset') || undefined,
                                limit: searchParams.get('limit') || undefined,
                            }} total={total}
                                           onPageChange={handlePageChange}
                                           showButtons={true}
                                           pageSizes={PAGE_SIZE}
                            />

                        </div>
                        <div className="download">

                        </div>
                    </div>
                </CardBody>
            </Card>
        </AppSuspense>
    );
};

export {CommonGrid};
