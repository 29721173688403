import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import baseRequest from '../../utils/base-request';
import {resetSpecState} from './spec-slice';
import {resetNodeTypeState} from './node-type-slice';
import {resetDictionaryTypeState} from './dictionary-type-slice';
import {resetDictionaryState} from './dictionary-slice';
import {resetNodeState} from './node-slice';
import {resetProductState} from './product-slice';
import {resetUserState} from './user-slice';
import {resetSettingsState} from './settings-slice';
import {resetProjectState} from "./project-slice";
import {resetUserGroupState} from "./user-group-slice";

export const userLogin = createAsyncThunk(
    'auth/login',
    async function (data: any, {rejectWithValue, dispatch}) {

        const response = await baseRequest({
            method: 'post',
            url: '/api/auth/login',
            data
        }, dispatch);

        return response.data;

    }
);

export const userLogout = createAsyncThunk(
    'auth/logout',
    async function (_, {rejectWithValue, dispatch}) {
        const response = await baseRequest({
            url: '/api/auth/logout',
            method: 'post'
        }, dispatch);

        dispatch(resetNodeTypeState());
        dispatch(resetNodeState());
        dispatch(resetDictionaryTypeState());
        dispatch(resetDictionaryState());
        dispatch(resetSpecState());
        dispatch(resetProductState());
        dispatch(resetUserState());
        dispatch(resetSettingsState());
        dispatch(resetProjectState());
        dispatch(resetUserGroupState());

        return response.data;
    }
);


export const ssoLogin = createAsyncThunk(
    'auth/ssoLogin',
    async function (_, {rejectWithValue, dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: '/sso/signin',
        }, dispatch);

        return response.data;

    }
);



const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthorized: false
    },
    reducers: {
        login(state) {
            // Cookies.set('isAuthorized', '1');
            state.isAuthorized = true;
        },
        logout(state) {
            // Cookies.remove('isAuthorized');
            state.isAuthorized = false;
        }
    },
    extraReducers: {

        [userLogin.fulfilled as any]: (state) => {
            authSlice.caseReducers.login(state);
        },
        [userLogin.rejected as any]: (state) => {
            authSlice.caseReducers.logout(state);
        },
        [userLogout.fulfilled as any]: (state) => {
            authSlice.caseReducers.logout(state);
        },
        [userLogout.rejected as any]: (state) => {
            authSlice.caseReducers.logout(state);
        },

    }
});

export const {login, logout} = authSlice.actions;

export default authSlice.reducer;
