import {SectionHeader} from "../../components/section-header/section-header";
import {SEARCH_PARAMS, SECTION_HEADER} from "../../constants";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {setSearchParamsTerm} from "../../helpers";
import {SearchParams} from "../../interface";
import {useNavigate, useSearchParams} from "react-router-dom";
import ObjectHelper from "../../helpers/object.helper";
import {useAppDispatch, useAppSelector} from "../../hook/store";
import {getProjectList, ProjectState} from "../../store/slice/project-slice";
import {AppBreadcrumbs} from "../../components/app-breadcrumbs/app-breadcrumbs";
import {AppBreadcrumb} from "../../interface/breadcrumbs.interface";
import {NewProjectDialog} from "./components/new-project-dialog";
import {CommonGrid} from "../../components/common-grid/common-grid";
import {projectColumns} from "./project.meta";
import {GridCellProps} from "@progress/kendo-react-grid";
import {ApiProject, ProjectAction} from "../../interface/api-project.interface";
import {ProjectActions} from "./components/project-actions";
import {DropDownButtonItem} from "@progress/kendo-react-buttons";
import {ROUTE_PATH} from "../../constants/routes";

const ProjectList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [newProject, setNewProject] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<SearchParams>({});
    const {projectList, total} = useAppSelector<ProjectState>(state => state.project);

    const breadcrumbsItems = useMemo((): AppBreadcrumb[] => {
        return [
            {title: 'Проекты', path: '/'}
        ]
    }, []);

    const toggleNewProjectDialog = () => {
        setNewProject((prev) => !prev);
    };

    const onSearch = (searchTerm: string): void => {
        handleSearchChange(setSearchParamsTerm(searchTerm, {...filterParams, offset: '0'}));
    };

    const handleSearchChange = (params: Partial<SearchParams>) => {
        setSearchParams(params as unknown as URLSearchParams);
    };

    const createProject = () => {
        toggleNewProjectDialog();
    };


    const getProjects = () => {
        const params: SearchParams = ObjectHelper.pickParams(Object.fromEntries(searchParams), SEARCH_PARAMS) as SearchParams;
        setFilterParams(params);
        dispatch(getProjectList(params));
    };

    useEffect(() => {
        if (!projectList) {
            getProjects();
        }
    }, [projectList]);


    const gridActions = (props: GridCellProps): ReactElement => {
        const project:ApiProject = props.dataItem;
        return <td style={{textAlign: 'right'}}>
            <ProjectActions project={props.dataItem}>
                <DropDownButtonItem text={ProjectAction.edit}/>
                {!project.specsCount && <DropDownButtonItem text={ProjectAction.delete}/>}
                <DropDownButtonItem text={ProjectAction.addSpec}/>
                {!!project.specsCount && <DropDownButtonItem text={ProjectAction.deleteSpec}/>}
                <DropDownButtonItem text={ProjectAction.rename}/>
                <DropDownButtonItem text={ProjectAction.permissions}/>
            </ProjectActions>
        </td>;
    };

    const handleRowClick = (data: any): void => {
        if (data.nativeEvent?.ctrlKey || data.nativeEvent?.metaKey) {
            window.open(`/${ROUTE_PATH.projects}/${data.dataItem.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.projects}/${data.dataItem.id}`);
        }

    };

    return (
        <>
            <div className={'page-grid inline-header'}>
                <div className="page-grid__breadcrumbs">
                    <AppBreadcrumbs items={breadcrumbsItems}/>
                </div>
                <div className="page-grid__header">
                    <SectionHeader onCreate={createProject}
                                   type={SECTION_HEADER.products}
                                   addButtonText={'Добавить'}
                                   onSearch={onSearch}
                                   style={'inline'}
                    />
                </div>
                <div className="page-grid__grid">
                    <CommonGrid
                        columnsSet={projectColumns}
                        data={projectList}
                        total={total}
                        onRowClick={handleRowClick}
                        gridActions={gridActions}
                        requestData={getProjects}
                        exportLink={'/api/admin/nodeType/export/excel'}
                    />
                </div>
            </div>
            {newProject && (
                <NewProjectDialog handleClose={toggleNewProjectDialog}/>
            )}
        </>
    );
};

export {ProjectList as default};
