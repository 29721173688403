import {Autocomplete, CircularProgress, createTheme, TextField, ThemeProvider} from '@mui/material';
import {OptionListItem} from '../../../../interface/common.interface';
import * as React from 'react';
import {
    AutocompleteChangeReason,
} from '@mui/base/useAutocomplete/useAutocomplete';
import {useEffect, useMemo, useState} from 'react';
import {multiselectTheme} from './app-multiselect.meta';
import {Close} from '@mui/icons-material';
import useDebounce from "../../../../hook/use-debounce";
import {setSearchFocus} from "../../../../store/slice/utils.slice";


interface AppMultiselectProps {
    options: OptionListItem[] | undefined;
    selected?: OptionListItem[];
    placeholder?: string;
    limitTags?: number;
    onChange: (event: React.SyntheticEvent,
               value: any,
               reason: AutocompleteChangeReason
    ) => void;
    onSearchChange?: (value: string) => void // useful for async data load
}


const theme = createTheme(multiselectTheme);


const AppMultiselect = ({options, selected, limitTags, placeholder, onChange, onSearchChange}: AppMultiselectProps) => {

    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const loading = useMemo(() => !options, [options]);

    const debounce = useDebounce(search);


    useEffect(() => {
        if (debounce !== undefined && onSearchChange) {
            onSearchChange(search)
        }

    }, [debounce]);

    return (
        <ThemeProvider theme={theme}>
            <Autocomplete
                multiple
                limitTags={limitTags || 2}
                open={open}
                options={options || []}
                getOptionLabel={(option) => option.title}
                defaultValue={selected || []}
                onChange={onChange}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                ChipProps={{deleteIcon: <Close/>}}
                filterSelectedOptions
                noOptionsText={"Список пуст"}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder || undefined}
                        onChange={(v) => setSearch(v.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />

                )}
                sx={{width: '500px'}}
            />
        </ThemeProvider>
    );
};

export {AppMultiselect};
