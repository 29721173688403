import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import baseRequest from "../../utils/base-request";
import {
    ApiUserGroup, ApiUserGroupDetails,
    ApiUserGroupListResult,
    ApiUserGroupModifyRequest,
    SearchParams
} from "../../interface";

export interface UserGroupState {
    groupList: ApiUserGroup[] | null;
    selectedGroup: ApiUserGroupDetails | null;
    total: number;
    searchParams: SearchParams;
}


export const getUserGroupList = createAsyncThunk(
    'userGroup/getUserGroupList',
    async function (params: SearchParams, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'get',
                url: '/api/userGroup',
                params
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);

export const createUserGroup = createAsyncThunk(
    'userGroup/getUserGroupList',
    async function (name: string, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'post',
                url: '/api/userGroup',
                data: {
                    name
                }
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);

export const renameUserGroup = createAsyncThunk(
    'userGroup/renameUserGroup',
    async function (groupData:{id: number; name: string }, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'put',
                url: `/api/userGroup/${groupData.id}/rename`,
                data: {
                    name: groupData.name
                }
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);


export const deleteUserGroup = createAsyncThunk(
    'userGroup/deleteUserGroup',
    async function (id: number, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'delete',
                url: `/api/userGroup/${id}`,
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);

export const getUserGroup = createAsyncThunk(
    'userGroup/getUserGroup',
    async function (id: number, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'get',
                url: `/api/userGroup/${id}`,
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);


export const addGroupUsers = createAsyncThunk(
    'userGroup/addGroupUsers',
    async function (data: ApiUserGroupModifyRequest, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'put',
                url: '/api/userGroup/addUsers',
                data
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);

export const removeGroupUsers = createAsyncThunk(
    'userGroup/getUserGroupUsers',
    async function (data: ApiUserGroupModifyRequest, {dispatch}) {
        try {
            const response = await baseRequest({
                method: 'put',
                url: '/api/userGroup/removeUsers',
                data
            }, dispatch);
            return response.data;
        } catch (e: any) {
            throw new Error(e);
        }
    }
);


const initialState: UserGroupState = {
    groupList: null,
    selectedGroup: null,
    total: 0,
    searchParams: {} as SearchParams
};


const userGroupSlice = createSlice({
    name: 'userGroup',
    initialState,
    reducers: {
        clearSelectedGroup(state: UserGroupState) {
            state.selectedGroup = null;
        },
        setSelectedGroup(state: UserGroupState, action: PayloadAction<ApiUserGroupDetails>) {
            state.selectedGroup = action.payload;
        },
        resetUserGroupState() {
            return initialState;
        },
    },
    extraReducers: {
        [getUserGroupList.fulfilled as any]: (state: UserGroupState, action: PayloadAction<ApiUserGroupListResult>) => {
            state.groupList = action.payload.result;
            state.total = action.payload.total;
        }

    }
});


export const {resetUserGroupState, clearSelectedGroup, setSelectedGroup} = userGroupSlice.actions;

export default userGroupSlice.reducer;
