import {AppBreadcrumbs} from "../../../components/app-breadcrumbs/app-breadcrumbs";
import {SectionHeader} from "../../../components/section-header/section-header";
import {SECTION_HEADER} from "../../../constants";
import {AppBreadcrumb} from "../../../interface/breadcrumbs.interface";
import {ROUTE_PATH} from "../../../constants/routes";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hook/store";
import {clearSelectedProject, getProject, ProjectState, selectProject} from "../../../store/slice/project-slice";
import {ProjectItemGrid} from "./project-item-grid";
import {ProjectAddSpecDialog} from "../components/project-add-spec-dialog";
import {clearSpecList} from "../../../store/slice/spec-slice";
import {Button} from "@mui/material";
import {AppPermissions} from "../../../components/app-permissions/app-permissions";
import axios from "axios";
import {ApiPermissionItem, ApiPermissionProject} from "../../../interface/api-permission.interface";


const ProjectItem = () => {

    const {id} = useParams();
    const dispatch = useAppDispatch();
    const {selectedProject} = useAppSelector<ProjectState>(state => state.project);
    const [specDialog, setSpecDialog] = useState<boolean>(false);
    const [permissionsDialog, setPermissionsDialog] = useState<boolean>(false);

    const breadcrumbsItems = useMemo((): AppBreadcrumb[] => {
        return [
            {title: 'Проекты', path: `/${ROUTE_PATH.projects}`},
            {title: selectedProject?.name || '', path: '/'}
        ]
    }, [selectedProject]);

    const [search, setSearch] = useState<string>('');


    const loadProjectData = () => {
        dispatch(getProject(+id!)).unwrap().then((data) => {
            dispatch(selectProject(data))
        });
    };

    const addSpecifications = () => {
        setSpecDialog(true)
    };

    const closeSpecDialog = (confirm?: boolean) => {
        dispatch(clearSpecList());
        setSpecDialog(false);
        if (confirm) {
            loadProjectData();
        }
    };

    useEffect(() => {
        if (id) {
            loadProjectData();
        }
        return () => {
            if (selectedProject) {
                dispatch(clearSelectedProject());
            }
        };
    }, [id]);

    const getProjectUsers = useCallback(() => {
        return axios.get(`/api/permission/project/${id}/all`).then((res) => res.data);
    }, [id]);

    const getProjectSave = useCallback((items: ApiPermissionItem[]) => {
        const data: ApiPermissionProject = {
            projectId: String(id),
            userIds: items.filter((u) => u.type === "user").map((u) => u.id),
            userGroupIds: items.filter((g) => g.type === "userGroup").map((g) => g.id),
        }
        return axios.put("/api/permission/project", data).then((res) => res.data);
    }, [id])

    return (
        <>
            <div className={'page-grid inline-header'}>
                <div className="page-grid__breadcrumbs">
                    <AppBreadcrumbs items={breadcrumbsItems}/>
                </div>
                <div className="page-grid__header">
                    <SectionHeader type={SECTION_HEADER.products}
                                   onCreate={addSpecifications}
                                   addButtonText={'Добавить'}
                                   onSearch={setSearch}
                                   style={'inline'}
                    >
                        <Button
                            type={'button'}
                            variant={'outlined'}
                            onClick={() => setPermissionsDialog(true)}
                        >
                            Управлять доступом
                        </Button>
                    </SectionHeader>
                </div>
                <div className="page-grid__grid">
                    <ProjectItemGrid project={selectedProject} searchTerm={search}/>
                </div>
            </div>
            {specDialog && <ProjectAddSpecDialog projectId={+id!} handleClose={closeSpecDialog}/>}
            {permissionsDialog && (
                <AppPermissions data={getProjectUsers} onSave={getProjectSave}
                                handleClose={() => setPermissionsDialog(false)}/>
            )}
        </>
    );
};

export {ProjectItem};
