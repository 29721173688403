import {useAppDispatch} from "../../../hook/store";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {OptionListItem} from "../../../interface/common.interface";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormHelperText,
    FormLabel
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {AppMultiselect} from "../../admin/components/multiselect/app-multiselect";
import {LoadingButton} from "@mui/lab";
import {getProject, getProjectList, removeProjectSpec} from "../../../store/slice/project-slice";
import {SpecItem} from "../../../interface";
import {ApiProjectDetails} from "../../../interface/api-project.interface";


interface ProjectRemoveSpecDialog {
    projectId: number,
    handleClose: () => void;
}

const ProjectRemoveSpecDialog = ({projectId, handleClose}: ProjectRemoveSpecDialog) => {

    const dispatch = useAppDispatch();
    const [params, setParams] = useSearchParams();
    const [availableSpecs, setAvailableSpecs] = useState<OptionListItem[] | undefined>();
    const [inProgress, setInProgress] = useState<boolean>(false);


    const {
        handleSubmit,
        control,
        setError,
        formState: {errors, isValid},
    } = useForm<any>({mode: 'all'});


    const onSubmit = async (data: any) => {
        if (!data.specifications.length) {
            setError("specifications", {type: "custom", message: "Спецификации не выбраны"});
        } else {
            setInProgress(true)
            const ids = data.specifications.map((s:OptionListItem) => +s.value.replace(/\.\s*(\w+)/g, ''));
            const specIds: number[] = Array.from(new Set(ids));
            dispatch(removeProjectSpec({projectId: projectId, specIds})).unwrap().then(() => {
                setInProgress(false);
                dispatch(getProjectList(Object.fromEntries(params.entries())));
                handleClose();
            }).finally(() => setInProgress(false));
        }
    };

    useEffect(() => {
        if(projectId){
            dispatch(getProject(projectId)).unwrap()
                .then((data: ApiProjectDetails) => {
                   setAvailableSpecs(data.specs.map((spec: SpecItem) => ({title: `${spec.id} - ${spec.name}`, value: spec.id})))
                });
        }
    }, [projectId])

    return (
        <>
            <Dialog
                className="new-item-dialog"
                open={true}
                maxWidth={'lg'}
                onClose={() => handleClose()}
            >
                <DialogTitle className="dialog-title">Удаление спецификаций из проекта</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <FormGroup className={errors?.users ? 'form-field form-field-error' : 'form-field'}>
                            <FormControl>
                                <FormLabel>
                                    Спецификации
                                </FormLabel>
                                <Controller
                                    control={control}
                                    name="specifications"
                                    defaultValue={[]}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <AppMultiselect
                                            options={availableSpecs}
                                            selected={[]}
                                            onChange={(e, v) => onChange(v)}
                                            limitTags={10}
                                        />
                                    )}/>
                            </FormControl>
                            <FormHelperText style={{
                                color: '#d32f2f',
                                fontSize: '0.75rem'
                            }}>{errors?.specifications && errors.specifications.message as string}</FormHelperText>
                        </FormGroup>


                    </DialogContent>
                    <DialogActions className="form-buttons">
                        <Button
                            disabled={inProgress}
                            variant={'outlined'}
                            onClick={() => handleClose()}
                        >
                            Отмена
                        </Button>
                        <LoadingButton
                            loading={inProgress}
                            type={'submit'}
                            variant={'contained'}
                            disabled={!isValid || inProgress}
                        >
                            Удалить
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export {ProjectRemoveSpecDialog};
