import {ApiUser} from "./user.interface";

export interface ApiUserGroupListResult{
    total: number;
    result: ApiUserGroup[];
}

export interface ApiUserGroup{
    id: number;
    name: string;
    usersCount: number;
}


export interface ApiUserGroupDetails extends ApiUserGroup{
    users: ApiUser[];
}

export interface ApiUserGroupModifyRequest{
    userGroupId: number;
    userIds: number[];
}



export enum UserGroupAction {
    delete = 'Удалить',
    edit = 'Перейти',
    rename = 'Переименовать',
    addUsers = 'Добавить пользователей',
    deleteUsers = 'Удалить пользователей',
}
