import {Card, CardActions, CardBody, CardTitle} from '@progress/kendo-react-layout';
import './sidebar.scss';
import logo from '../../assets/img/logo.svg';
import logoMini from '../../assets/img/logoMini.svg';
import {Button} from '@progress/kendo-react-buttons';
import {ReactComponent as IconUser} from '../../assets/img/icon/user.svg';
import {Link, NavLink} from 'react-router-dom';
import 'primeicons/primeicons.css';
import {ProtectedLayout} from '../../hoc/protected-layout/protected-layout';
import {ROUTE_PATH} from '../../constants/routes';
import arrow from '../../assets/img/icon/sidebarArrow.svg';
import {DEFAULT_PAGE_SIZE, PAGE_SIZE} from "../../constants";

interface SidebarProps {
    isExpanded: boolean;
    handleChangeSidebarState: () => void
}

const Sidebar = ({isExpanded, handleChangeSidebarState}: SidebarProps) => {

    const activeClassNames = 'k-button k-button-lg k-button-rectangle k-button-solid k-button-solid-tertiary k-rounded-md list-button';
    const inactiveClassNames = 'k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-secondary k-rounded-md list-button';

    const setActive = ({isActive}: any) => isActive ? activeClassNames : inactiveClassNames;

    return <>
        <Card className="configurator-sidebar-card">
            <CardTitle className={`${isExpanded ? 'logo-container' : 'logo-container collapsed'}`}>
                <img title={!isExpanded ? 'Конфигуратор' : ''} src={isExpanded ? logo : logoMini} alt={'Конфигуратор'}/>
            </CardTitle>
            <CardBody className={`${!isExpanded && 'collapsed'}`}>
                <div className={`${isExpanded ? 'item' : 'item collapsed'}`}>
                    {isExpanded && <p>Спецификации</p>}
                    <NavLink className={setActive} to={`/${ROUTE_PATH.specifications}`}
                             title={!isExpanded ? 'Список' : ''}>
                        <span className="k-button-text"><i className="pi pi-list"/>{isExpanded && 'Список'}</span>
                    </NavLink>
                    <NavLink className={setActive} to={`/${ROUTE_PATH.templates}`}
                             title={!isExpanded ? 'Шаблоны' : ''}>
                        <span className="k-button-text"><i className="pi pi-file"/>{isExpanded && 'Шаблоны'}</span>

                    </NavLink>
                    <NavLink className={setActive} to={`/${ROUTE_PATH.projects}`}
                             title={!isExpanded ? 'Проекты' : ''}>
                        <span className="k-button-text"><i className="pi pi-folder"/>{isExpanded && 'Проекты'}</span>

                    </NavLink>
                </div>
                <ProtectedLayout>
                    <div className={`${isExpanded ? 'item' : 'item collapsed'}`}>
                        {isExpanded && <p>Библиотека</p>}
                        <NavLink className={setActive} to={`/${ROUTE_PATH.newComponents}`}
                                 title={!isExpanded ? 'Компоненты' : ''}>
                       <span className="k-button-text">
                       <i className="pi pi-sitemap"/>{isExpanded && 'Компоненты'}
                       </span>
                        </NavLink>
                        <NavLink className={setActive} to={`/${ROUTE_PATH.dictionary}`}
                                 title={!isExpanded ? 'Справочники' : ''}>
                       <span className="k-button-text">
                            <i className="pi pi-book"></i>{isExpanded && 'Справочники'}
                       </span>
                        </NavLink>
                        <NavLink className={setActive} to={`/${ROUTE_PATH.configurations}`}
                                 title={!isExpanded ? 'Конфигурации' : ''}>
                           <span className="k-button-text">
                            <i className="pi pi-cog"/>{isExpanded && 'Конфигурации'}
                           </span>
                        </NavLink>

                        {/*<NavLink className={setActive} to={`/${ROUTE_PATH.nodeTypes}`}*/}
                        {/*         title={!isExpanded ? 'Типы узлов' : ''}>*/}
                        {/*   <span className="k-button-text">*/}
                        {/*    <i className="pi pi-tag"/>{isExpanded && 'Типы узлов'}*/}
                        {/*   </span>*/}
                        {/*</NavLink>*/}
                    </div>
                    <div className={`${isExpanded ? 'item' : 'item collapsed'}`}>
                        {isExpanded && <p>Безопасность</p>}
                        <NavLink className={setActive} to={`/${ROUTE_PATH.users}?offset=0&limit=${DEFAULT_PAGE_SIZE}`}
                                 title={!isExpanded ? 'Пользователи' : ''}>
                                   <span className="k-button-text">
                                       <i className="pi pi-user"></i>{isExpanded && 'Пользователи'}
                                   </span>
                        </NavLink>
                        <NavLink className={setActive} to={`/${ROUTE_PATH.userGroups}`}
                                 title={!isExpanded ? 'Группы' : ''}>
                                   <span className="k-button-text">
                                       <i className="pi pi-users"></i>{isExpanded && 'Группы'}
                                   </span>
                        </NavLink>
                    </div>
                    <div className={`${isExpanded ? 'item' : 'item collapsed'}`}>
                        {isExpanded && <p>Администрирование</p>}
                        <NavLink className={setActive} to={`/${ROUTE_PATH.adminNodes}?offset=0&limit=${DEFAULT_PAGE_SIZE}`}
                                 title={!isExpanded ? 'Компоненты' : ''}>
                                   <span className="k-button-text">
                                       <i className="pi pi-sitemap"></i>{isExpanded && 'Компоненты'}
                                   </span>
                        </NavLink>
                        <NavLink className={setActive} to={`/${ROUTE_PATH.adminNodeTraits}?offset=0&limit=${DEFAULT_PAGE_SIZE}`}
                                 title={!isExpanded ? 'Характеристики' : ''}>
                                   <span className="k-button-text">
                                       <i className="pi pi-sliders-h"></i>{isExpanded && 'Характеристики'}
                                   </span>
                        </NavLink>
                    </div>
                </ProtectedLayout>
            </CardBody>
            <CardActions className={isExpanded ? '' : 'collapsed'}>
                <Link to={`/${ROUTE_PATH.settings}`} title={!isExpanded ? 'Настройки' : ''}>
                    <Button
                        className="list-button"
                        fillMode={'link'}
                    >
                        <IconUser/>{isExpanded && 'Настройки'}
                    </Button>
                </Link>
            </CardActions>
        </Card>
        <div
            className="sideBarArrow"
        >
            <img
                src={arrow}
                alt="menu arrow"
                title={isExpanded ? 'Свернуть меню' : 'Развернуть меню'}
                onClick={handleChangeSidebarState}
                style={{transform: isExpanded ? '' : 'rotate(180deg)'}}
            />
        </div>
    </>;
};

export {Sidebar};
