import {ApiProject, ProjectAction} from "../../../interface/api-project.interface";
import React, {PropsWithChildren, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../hook/store";
import {DropDownButtonItemClickEvent} from "@progress/kendo-react-buttons/dist/npm/ListButton/models/events";
import {AppPromptData} from "../../../interface";
import {DropDownButton} from "@progress/kendo-react-buttons";
import {AppPrompt} from "../../../components/app-prompt/app-prompt";
import {ROUTE_PATH} from "../../../constants/routes";
import {deleteProject} from "../../../store/slice/project-slice";
import {ProjectAddSpecDialog} from "./project-add-spec-dialog";
import {ProjectRemoveSpecDialog} from "./project-remove-spec-dialog";
import {RenameProjectDialog} from "./rename-project-dialog";
import {clearSpecList} from "../../../store/slice/spec-slice";
import {AppPermissions} from "../../../components/app-permissions/app-permissions";
import axios from "axios";
import {API_ADMIN_NODE} from "../../admin/admin.meta";
import {ApiPermissionItem, ApiPermissionProject} from "../../../interface/api-permission.interface";

interface ProjectActionsProps extends PropsWithChildren {
    project: ApiProject
}

const ProjectActions = ({project, children}: ProjectActionsProps) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showAddSpecs, setShowAddSpecs] = useState<boolean>(false);
    const [showRemoveSpec, setShowRemoveSpec] = useState<boolean>(false);
    const [renameDialog, setRenameDialog] = useState<boolean>(false);
    const [usersDialog, setUsersDialog] = useState<boolean>(false);

    const actionData: AppPromptData = {
        title: 'Подтвердите действие',
        message: [`Подтвердите удаление проекта "${project?.name}"`],
        confirmButton: 'Удалить',
        buttonError: true
    };

    const handleAction = (e: DropDownButtonItemClickEvent) => {
        switch (e.item.text) {
            case ProjectAction.edit:
                goToProject(e);
                break;
            case ProjectAction.delete:
                setShowConfirm(true);
                break;
            case ProjectAction.addSpec:
                setShowAddSpecs(true);
                break;
            case ProjectAction.deleteSpec:
                setShowRemoveSpec(true);
                break;
            case ProjectAction.rename:
                setRenameDialog(true);
                break;
            case ProjectAction.permissions:
                setUsersDialog(true);
                break;
            default:
                break;
        }
    };

    const itemRender = (itemData: { item: any; itemIndex: number }) => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };


    const onDeleteProject = () => {
        setShowConfirm(false);
        if (project.id) {
            dispatch(deleteProject(project.id)).unwrap()
                .then(() => {
                    navigate(`/${ROUTE_PATH.projects}`);
                });
        }
    };

    const goToProject = (e: DropDownButtonItemClickEvent) => {
        if (e.nativeEvent?.ctrlKey || e.nativeEvent?.metaKey) {
            window.open(`/${ROUTE_PATH.projects}/${project.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.projects}/${project.id}`);
        }
        if (e.nativeEvent) {
            e.nativeEvent.stopPropagation();
        }
    };

    const handleAddDialogClose = () => {
        dispatch(clearSpecList());
        setShowAddSpecs(false);
    };

    const getProjectUsers = useCallback(() => {
        return axios.get(`/api/permission/project/${project.id}/all`).then((res) => res.data);
    }, [project]);

    const getProjectSave = useCallback((items: ApiPermissionItem[]) => {
        const data: ApiPermissionProject = {
            projectId: String(project.id),
            userIds: items.filter((u) => u.type === "user").map((u) => u.id),
            userGroupIds: items.filter((g) => g.type === "userGroup").map((g) => g.id),
        }
        return axios.put("/api/permission/project", data).then((res) => res.data);
    }, [project])

    return (
        <>
            <DropDownButton
                size={'small'}
                themeColor={'primary'}
                fillMode={'solid'}
                rounded={'small'}
                icon={'more-vertical'}
                itemRender={itemRender}
                onItemClick={handleAction}
            >
                {children}
            </DropDownButton>
            {showConfirm && (
                <AppPrompt data={actionData} onClose={() => setShowConfirm(false)} onConfirm={onDeleteProject}/>
            )}
            {showAddSpecs && (
                <ProjectAddSpecDialog projectId={project.id} handleClose={handleAddDialogClose}/>
            )}
            {showRemoveSpec && (
                <ProjectRemoveSpecDialog projectId={project.id} handleClose={() => setShowRemoveSpec(false)}/>
            )}
            {renameDialog && (
                <RenameProjectDialog project={project} handleClose={() => setRenameDialog(false)}/>
            )}
            {usersDialog && (
                <AppPermissions data={getProjectUsers} onSave={getProjectSave}
                                handleClose={() => setUsersDialog(false)}/>
            )}
        </>
    );
};

export {ProjectActions};
